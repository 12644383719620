import React from "react";

function Presskit() {
  const handleDownload = (fileName) => {
    const fileUrl = `/pdfs/${fileName}`;
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="info">
      <div className="info_container">
        <p>
          Manduba is a dub live trío based in Barcelona.
        </p>
        <p>
          For more than 20 years they have been mixing reggae dub and its siblings 
          with dubstep and steppas beats, original songs, live vocals and brasses.
        </p>
        <p>
          In this moment of their stay in this planet, they converge their
          energies with a versatile format that allows them to deliver a high
          energy and atmospheric concert or take control of a soundsystem with a
          live show.
        </p>
             {/* SoundCloud iframe */}
      <div className="soundcloud-container">
        <iframe 
          width="100%" 
          height="300" 
          scrolling="no" 
          frameBorder="0" 
          allow="autoplay" 
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1986666875&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
        />
        <div className="soundcloud-info">
          <a 
            href="https://soundcloud.com/manduba" 
            title="Manduba" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            Manduba
          </a> · 
          <a 
            href="https://soundcloud.com/manduba/battle-for-your-brain" 
            title="Battle for your brain" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            Battle for your brain
          </a>
        </div>
      </div>
        
        <div className="download_section">
          <h3>Technical Information</h3>
          <div className="download_buttons">
            <button
              onClick={() => handleDownload('RIDER_MANDUBA.pdf')}
              className="download_button"
            >
              <svg
                className="download_icon"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                ></path>
              </svg>
              Download Technical Rider
            </button>
            
            <button
              onClick={() => handleDownload('MANDUBA_STAGE.pdf')}
              className="download_button"
            >
              <svg
                className="download_icon"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                ></path>
              </svg>
              Download Stage Plan
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Presskit;