import React from 'react';

function Music() {
  return (
    <div className="music">
      {/* SoundCloud iframe */}
      <div className="soundcloud-container">
        <iframe 
          width="100%" 
          height="300" 
          scrolling="no" 
          frameBorder="0" 
          allow="autoplay" 
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1986666875&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
        />
        <div className="soundcloud-info">
          <a 
            href="https://soundcloud.com/manduba" 
            title="Manduba" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            Manduba
          </a> · 
          <a 
            href="https://soundcloud.com/manduba/battle-for-your-brain" 
            title="Battle for your brain" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            Battle for your brain
          </a>
        </div>
      </div>

      {/* YouTube iframes */}
      <iframe 
        width="560" 
        height="315" 
        src="https://www.youtube.com/embed/DtlOHKuT0ug?si=JCphqX1NkP8TdtC8" 
        title="YouTube video player" 
        frameBorder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        referrerPolicy="strict-origin-when-cross-origin" 
        allowFullScreen
      />
      <iframe 
        width="560" 
        height="315" 
        src="https://www.youtube.com/embed/AbLkFBquR9Q?si=DhdWdAh0MNC6rw7y" 
        title="YouTube video player" 
        frameBorder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        referrerPolicy="strict-origin-when-cross-origin" 
        allowFullScreen
      />
    </div>
  );
}

export default Music;