import { useGlitch } from "react-powerglitch";
import "./App.css";
import Nav from "./components/NavMenu/NavMenu";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ReactComponent as InstagramIcon } from "./instagram_logo.svg";
import { ReactComponent as SoundcloudIcon } from "./soundcloud.svg";
import { ReactComponent as YoutubeIcon } from "./youtube.svg";
import Home from "./pages/Home";
import Info from "./pages/Info";
import Music from "./pages/Music";
import Images from "./pages/Images";
import Contact from "./pages/Contact";
import Presskit from "./pages/Presskit";

function App() {
  const glitch = useGlitch({
    glitchTimeSpan: {
      start: 0.15,
      end: 0.65,
    },
    pulse: {
      scale: 1.6,
    },
  });

  return (
    <BrowserRouter>
      <div className="App">
        <img
          ref={glitch.ref}
          src="manduba_logo.png"
          className="logo"
          alt="Manduba"
        />
        <div className="content-wrapper">
          <Nav />
          <main className="main-content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/info" element={<Info />} />
              <Route path="/music" element={<Music />} />
              <Route path="/images" element={<Images />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/presskit" element={<Presskit />} />
            </Routes>
          </main>
        </div>
        <footer className="footer">
          <div className="social_icons">
          <a
            href="https://www.instagram.com/mandubadub"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:opacity-80 transition-opacity"
          >
            <InstagramIcon
              style={{
                filter: "brightness(0) invert(1)",
                width: "32px",
                height: "32px",
                transition: "opacity 0.3s ease",
                marginRight: "16px"
              }}
            />
          </a>
          <a
            href="https://soundcloud.com/manduba"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:opacity-80 transition-opacity"
          >
            <SoundcloudIcon
              style={{
                filter: "brightness(0) invert(1)",
                width: "32px",
                height: "32px",
                transition: "opacity 0.3s ease",
                marginRight: "16px"
              }}
            />
          </a>
          <a
            href="https://www.youtube.com/@Manduba-v8k"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:opacity-80 transition-opacity"
          >
            <YoutubeIcon
              style={{
                filter: "brightness(0) invert(1)",
                width: "32px",
                height: "32px",
                transition: "opacity 0.3s ease",
              }}
            />
          </a>
          </div>
        </footer>
      </div>
    </BrowserRouter>
  );
}

export default App;